import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Company from "../components/danismanlik-company"
import rightArrow from "../assets/img/right-arrow.png";

import maddebir from "../assets/img/dd-madde1.png"
import maddeiki from "../assets/img/dd-madde2.png"
import maddeuc from "../assets/img/dd-madde3.png"
import maddedort from "../assets/img/dd-madde4.png"
import maddebes from "../assets/img/dd-madde5.png"
import maddealti from "../assets/img/dd-madde6.png"
import digital from "../assets/img/digital-danismanlik.png"
import work from "../assets/img/work-preformance.png"
import Teams from "../components/teams/digital-team"
import SSS from "../components/sss/social-sss"
import Checkup from "../components/checkup"
import checkModal from "../assets/img/check-modal.png";


class SocialMedia extends Component {
    constructor(props) {
        super(props);

        this.state = {
            display: false,
            value: ''
        };
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e) {
        e.preventDefault();

        this.setState({ value: e.target.value });
    }
    showModal() {
        this.setState({ display: !this.state.display });
        document.querySelector('.check-modal').classList.add('active-modal');

        document.querySelector('#site').innerHTML = this.state.value;
    }
    closeModal() {
        this.setState({ display: !this.state.display })
        document.querySelector('.check-modal').classList.remove('active-modal');
    }
    handleSubmit(e) {
        e.preventDefault();
        const title = document.title.split('|', 1).toString();
        const response = fetch('https://forms.flatart.com.tr/index.php?r=app%2Ff&id=7', {
            method: 'POST',
            body: JSON.stringify({
                "text_327649": title,
                "text_204202": document.querySelector('#site').value,
                "text_775908": document.querySelector('#name').value,
                "text_532192": document.querySelector('#email').value,
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        });
        response.then(res => {
            if (res.status === 200) {
                document.querySelector('.status').innerHTML = "Success!";
            }
            else {
                document.querySelector('.status').innerHTML = "Error";
            }
        });
    }
    render() {
        return (
            <Layout>
                <SEO title="Digital Marketing Consultancy"
                 description="We offer customized digital strategies for your brand. We help you grow your brand by improving your website and other digital channels." />
                <div className="seo-service container">
                    <div className="seo-service__head" style={{marginTop:50}}>
                        <div className="col-md-6 noPadding">
                            <h1>Digital Marketing <br />Consultancy</h1>
                            <span className="red-head">SERVICES</span>
                            <p> We determine the right strategy for our brands and ensure that they use digital technologies in the most accurate way. </p>
                            <input type="text" className="pink-input" id="email-marketing-input" placeholder="Enter your e-mail address" value={this.state.value} onChange={this.handleChange} />
                                    <button className="red-button" id="email-marketing-button" onClick={(e) => this.showModal()}>Get your special offer</button>
                        </div>
                        <div className="col-md-6" ><img  className="flatnew" alt="alt text" src={digital} /> </div>
                        <div className="check-modal">
                            <span onClick={(e) => this.closeModal()}>X</span>
                            <h2 className="form-head">Fill the form</h2>                               <p className="form-desc">Enter your information in the fields to get information, and we will get back to you as soon as possible.</p>
                            <div className="check-modal_container">
                            <form onSubmit={this.handleSubmit} >
                                    <input type="text" name="text_204202" id="site" required  placeholder="Enter your website." />
                                    <input type="text" id="name" required name="text_775908" placeholder="Enter your name" />
                                    <input type="email" id="email" required  name="text_532192" value={this.state.value} onChange={this.handleChange} autoFocus={true} placeholder="Enter yor email" />
                                    <button  >SEND</button>
                                    <div className="status"></div>
                                </form>
                                <img alt="alt text" src={checkModal} />
                            </div>
                        </div>
                       
                    </div>
                </div>
                <div className="container brands_content py60">
                    
                    <div className="studies">
                        <h2 >With our team with proven success, we guide your digital marketing efforts and take your brand a few <br /> steps forward in the digital world</h2>
                    </div>
                    <h2 class="py60">Our References</h2>
                     <p>Featured reference brands.</p>
                    <div className="companys__social">
                        <Company />
                    </div>
                </div>

                <div className="acquire-bottom social-media-contents" >
                    <div className="seo-services container" style={{background:"#f4f4f4", borderRadius:10 }}>
                        <div className="seo-services_head" style={{marginTop:30, marginBottom:70}}>
                            <h2>Why choose us for digital <br />marketing consultancy?</h2>
                        </div>
                        <div className="seo-services_content py60" id="seo-audits">
                        
                            <div className="col-md-12 content-list">
                                <div className="col-md-4 audits-info">
                                    <img alt="alt text" src={maddebir} style={{width:90}}/>
                                    <h3 style={{fontWeight:500,fontSize:23 }}>Tailor-made Digital Marketing Strategy</h3>
                                    <p style={{fontSize:16}}>We accelerate your growth with a digital marketing roadmap tailored to your brand's needs and goals.</p>
                                </div>
                                <div className="col-md-4 audits-info">
                                    <img alt="alt text" src={maddeiki} style={{width:90}} />
                                    <h3 style={{fontWeight:500,fontSize:23 }}>Integrated Marketing Communications</h3>
                                    <p style={{fontSize:16}}>A communication strategy is designed for all platforms where your brand needs to be represented, and customized solutions are produced for each platform.</p>
                                </div>
                                <div className="col-md-4 audits-info">
                                    <img alt="alt text" src={maddeuc} style={{width:90}} />
                                    <h3 style={{fontWeight:500,fontSize:23 }}>Technological Infrastructures</h3>
                                    <p style={{fontSize:16}}>Our team, which is well-versed in all areas of information technologies (IT), works to ensure that you have the right technological infrastructures to strengthen your presence in the digital world.</p>
                                </div>
                            
                            </div>

                        </div>
                        <div className="seo-services_content " id="seo-audits" style={{marginTop:-50}}>
                            <div className="col-md-12 content-list">
                                <div className="col-md-4 audits-info">
                                    <img alt="alt text" src={maddedort} style={{width:90}} />
                                    <h3 style={{fontWeight:500,fontSize:23 }}>Long-Term Outcomes</h3>
                                    <p style={{fontSize:16}}>We support your long-term growth by enabling you to quickly adapt to changes in the ever-evolving digital world.</p>
                                </div>
                                <div className="col-md-4 audits-info">
                                    <img alt="alt text" src={maddebes} style={{width:90}} />
                                    <h3 style={{fontWeight:500,fontSize:23 }}>Control Over the Whole Process </h3>
                                    <p style={{fontSize:16}}>From the point of the launching of the digital marketing strategy, regular reporting is carried out to ensure that you are involved and in control of the entire plan.</p>
                                </div>
                                <div className="col-md-4 audits-info">
                                    <img alt="alt text" src={maddealti} style={{width:90}} />
                                    <h3 style={{fontWeight:500,fontSize:23 }}>Budget-Friendly Prices</h3>
                                    <p style={{fontSize:16}}>The fee is determined according to your brand goals and scope of work. Accordingly, a monthly prepayment is charged. Apart from this, exceptional fees are not requested.</p>
                                </div>
                            
                            </div>
                        </div>
                    </div>

                </div>

                <Checkup />

                <div className="onboarding container py60" >
                    <div className="col-md-4">
                        <img alt="alt text" src={work} />
                        <h2>Our  <br />process.</h2>
                        <p>Take a look at how we run our digital consulting <br />process for our brands. </p>
                    </div>
                    <div className="col-md-8">
                        <div className="hiring-tabs">
                            <input type="checkbox" name="onboarding" id="tab6" />
                            <input type="checkbox" name="onboarding" id="tab7" />
                            <input type="checkbox" name="onboarding" id="tab8" />
                            <input type="checkbox" name="onboarding" id="tab9" />
                            <input type="checkbox" name="onboarding" id="tab10" />
                            <input type="checkbox" name="onboarding" id="tab11" />

                            <div className="tab" id="tab6sec">
                                <label htmlFor="tab6"> </label>
                                <h3> <span>1</span> Discovery meeting.</h3>
                                <p>-  In order to start the procedure, we conduct a meeting to get to know you and your brand. In this meeting, we focus on the sector that you are working in, as well as your goals, your competitive advantages, your target audience, and other factors affecting your performance. </p>
                                <div className="plus"></div>
                            </div>
                            <div className="tab" id="tab7sec">
                                <label htmlFor="tab7"> </label>
                                <h3> <span>2</span> Accounts are audited and opportunities are revealed.</h3>
                                <p>- We analyze the current performance in your social media channels line with the data that we will use while creating our future strategies.</p>
                                <div className="plus"></div>
                            </div>
                            <div className="tab" id="tab8sec">
                                <label htmlFor="tab8"> </label>
                                <h3> <span>3</span> Findings are discussed to move <br />on to the next steps.</h3>
                                <p>- We deliver a face-to-face or video talk to offer you growth opportunities, alongside a detailed business plan and ROI calculations.</p>
                                <div className="plus"></div>
                            </div>
                            <div className="tab" id="tab9sec">
                                <label htmlFor="tab9"> </label>
                                <h3> <span>4</span> An initial strategy is created<br /> and action is taken.</h3>
                                <p>- We focus on actions that will have a positive impact in the digital world, in line with the first 3-month priority plan based on the findings from our audit.</p>
                                <div className="plus"></div>
                            </div>
                            <div className="tab" id="tab10sec">
                                <label htmlFor="tab10"> </label>
                                <h3> <span>5</span> Optimizations and detailed monthly reports are maintained.</h3>
                                <p>- We meet with you weekly to review performance, follow the agenda and generate new ideas. </p>
                                <div className="plus"></div>
                            </div>
                            <div className="tab" id="tab11sec">
                                <label htmlFor="tab11"> </label>
                                <h3> <span>6</span> Strategic planning sessions for the next <br /> quarter are held every three months. </h3>
                                <p>- Ongoing optimizations and strategic plans are discussed, a detailed analysis evaluation is made for the next period, and your budget is managed to offer you the highest value.</p>
                                <div className="plus"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="acquire-bottom social-media-contents" >
                    <div className="seo-services container">
                        <div className="seo-services_head" style={{marginTop:30}}>
                            <h2>Advantages of Digital Marketing <br /> Consultancy Services</h2>
                        </div>
                        <div className="seo-services_content" id="seo-audits">
                            <div className="col-md-2"> </div>

                            <div className="col-md-8">   
                                    <h3 style={{fontWeight:500,fontSize:23 }}>Growth Opportunities</h3>
                                    <p style={{fontSize:17}}>Enables you to take advantage of the opportunities offered by next-generation technologies to strengthen your brand's presence in the digital world, regardless of scale.</p>
                                    <h3 style={{fontWeight:500,fontSize:23 }}>Increased Efficiency</h3>
                                    <p style={{fontSize:17}}>With our digital marketing experts preparing tailor-made strategies, you can use your valuable time and other resources more efficiently to grow your business.</p>
                                    <h3 style={{fontWeight:500,fontSize:23 }}>Achieving the Goals</h3>
                                    <p style={{fontSize:17}}>All the steps taken in line with your digital marketing strategy will help you achieve your goals faster.</p>
                                    <p style={{fontSize:17}}>You can contact Flatart's expert team to get more detailed information about digital marketing consultancy services, to discuss your project, or to cooperate.</p>


                            </div>
                            <div className="col-md-2"> </div>

                        </div>
                       
                    </div>

                </div>

                <div className="team">
                    <h2 style={{ textAlign: 'center', marginBottom: 120 }}> Our experts who can<br /> help you. </h2>
                    <Teams />
                </div>
                <div className="hiring" style={{ marginTop: -20 }}>
                    <h2>Frequently Asked Questions</h2>
                    <SSS />
                </div>
            </Layout>
        )
    }
}
export default SocialMedia
